import { Component } from "react"
import { Container ,Grid, Typography} from '@material-ui/core';
import { withThemeProps } from "@findep/microfronts-core"
import PropTypes from 'prop-types'
// FINANCIERA COMPONENTS
import {  CardElevation } from '@findep/mf-landings-core'
//ICONS MATERIAL UI


    
 
class CreditReportCmp extends Component {    

    render() {
        const {            
            titleIcon=<span><strong>Autorización de informe de crédito</strong><br/></span>,
            txtTitle=<span><strong>Español</strong><br/><br/>Al firmar a continuación, “usted”: (1) certifica que la información anterior es precisa y completa, y pretende que “nosotros” (Apoyo Financiero) confíe en ella para juzgar la solvencia crediticia; (2) nos autoriza a conservar como nuestra propiedad esta solicitud y todos los materiales relacionados ya sea que el crédito se extienda o no; (3) certifica que el préstamo no se usará para ningún propósito ilegal; (4) nos autoriza a nosotros y a nuestros afiliados a obtener informes de crédito del consumidor e investigaciones de su historial crediticio y laboral en relación con esta solicitud y cualquier actualización, revisión, recopilación o modificación del crédito extendido y para responder cualquier pregunta sobre nuestra experiencia de crédito con usted consistente con la ley aplicable y nuestra política de privacidad; (5) acepta notificarnos por escrito de los cambios en el nombre, dirección y otra información proporcionada anteriormente; y (6) ADMITE QUE HA GUARDADO UNA COPIA DE ESTA AUTORIZACIÓN, DESCARGANDOLA EN EL MOMENTO QUE LA FIRMÓ ELECTRÓNICAMENTE. TAMBIÉN PUEDE SOLICITAR UNA COPIA DIGITAL A SU REPRESENTANTE DE APOYO FINANCIERO.<br/><br/></span>,
            txtContent=<span><strong>English</strong><br/><br/>By checking the box and continuing with the application process, “you”: (1) certify that the information you provided Apoyo Financiero, Inc. over the telephone to be accurate and complete and intend it to be relied upon by “us” (Apoyo Financiero, Inc.) to judge creditworthiness; (2) authorize us to retain as our property this information and all related materials whether or not credit is extended; (3) certify that the loan will not be used for any illegal purpose; (4) authorize us and our affiliates to obtain consumer credit reports and investigations of your credit and employment history in connection with this application and any update, review, collection or modification of the credit extended and to answer any questions about our credit experience with you consistent with applicable law and our privacy policy; (5) agree to notify us in writing of changes in name, address, and other information provided above; and (6) for other related purposes. You understand that, after evaluating your complete application and checking your report, we may decide not to offer credit or products (including opening a savings account) to you. YOU MAY ALSO REQUEST A DIGITAL COPY FROM YOUR APOYO FINANCIERO REPRESENTATIVE.<br/><br/></span>,
            txtTitleEmp='',
                    
            
                    } = this.props
        return (
            <Container justify="center">
                <br/><br/>
                <Grid container justify="left">
                    <Grid item style={{ textAlign: "left" }}>
                        <Typography component='h3' variant="h4" >
                        <strong style={{color:'#698d21'}}>{titleIcon}</strong>                        
                        </Typography>
                        <Typography component='h3' variant="h6" >
                            <strong style={{color:'#e66728'}}>{txtTitleEmp}</strong>                        
                        </Typography>
                        
                    </Grid>
                </Grid>
                <br/><br/>
               <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <CardElevation>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtTitle}                       
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContent}                        
                    </Typography>
                    <br/>                                       
                    </CardElevation>
                    <br/><br/>
                    
                </Grid>
                
                </Grid>
            </Container>

        )
    }
}

CreditReportCmp.propTypes = {      
    titleIcon: PropTypes.string, 
    txtTitle: PropTypes.string, 
    txtContent: PropTypes.string, 
    txtTitleEmp: PropTypes.string,
    
    
    txtContentTwo: PropTypes.string, 
        
}

export default withThemeProps(CreditReportCmp, 'VDPNCreditReport')






